import { FC, memo } from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import {
  IcnAlergie,
  IcnBadaniaDNA,
  IcnBadaniaPrenatalne,
  IcnBadanieKrwi,
  IcnBadanieZWymazu,
  IcnMikrofloraJelitowa,
  IcnNietolerancjePokarmowe,
  IcnOpenBook,
  IcnSuplementy,
  IcnBadanieMoczu,
  IcnDostawaKurierem,
  IcnKoronawirus,
} from "theme/icons";
import {
  colorAlergieLightBg,
  colorGenetyczneLightBg,
  colorMikrofloraLightBg,
  colorPrenatalneLightBg,
  colorPrimary100,
  colorSuplementyLightBg,
  colorZKrwiLightBg,
} from "theme/colors";

const SystemCategoryIcon = memo(function SystemIcons({
  iconName,
  iconColor,
  backgroundColor,
  title,
}: {
  iconName: string;
  iconColor?: string;
  backgroundColor?: string;
  title?: string;
}) {
  switch (iconName) {
    case "IcnBadaniaDNA":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorGenetyczneLightBg
          }
          title={title}
        >
          <IcnBadaniaDNA className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnNietolerancjePokarmowe":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorPrimary100}
          title={title}
        >
          <IcnNietolerancjePokarmowe
            className={"f-transparent"}
            fill={iconColor}
          />
        </IconBox>
      );
    case "IcnBadaniaPrenatalne":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorPrenatalneLightBg
          }
          title={title}
        >
          <IcnBadaniaPrenatalne className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnMikrofloraJelitowa":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorMikrofloraLightBg
          }
          title={title}
        >
          <IcnMikrofloraJelitowa className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieKrwi":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorZKrwiLightBg}
          title={title}
        >
          <IcnBadanieKrwi className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnAlergie":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorAlergieLightBg}
          title={title}
        >
          <IcnAlergie className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnSuplementy":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorSuplementyLightBg
          }
          title={title}
        >
          <IcnSuplementy className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieMoczu":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : "#f3ebe4"}
          title={title}
        >
          <IcnBadanieMoczu className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieZWymazu":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorMikrofloraLightBg
          }
          title={title}
        >
          <IcnBadanieZWymazu className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnDostawaKurierem":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : "#F3F1FF"}
          title={title}
        >
          <IcnDostawaKurierem className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    case "IcnKoronawirus":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : "#fdeeee"}
          title={title}
        >
          <IcnKoronawirus className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
    default:
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : "#fdeeee"}
          title={title}
        >
          <IcnOpenBook className={"f-transparent"} fill={iconColor} />
        </IconBox>
      );
  }
});

type IconBoxProps = {
  children: any;
  borderColor: string;
  title?: string;
};

const IconBox: FC<IconBoxProps> = ({ children, borderColor, title }) => {
  return title ? (
    <Tooltip title={title} role="img">
      <Box
        component="span"
        className={"fcx-icon-box-mini-bg"}
        sx={{
          background: borderColor,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  ) : (
    <Box
      component="span"
      className={"fcx-icon-box-mini-bg"}
      sx={{
        background: borderColor,
      }}
    >
      {children}
    </Box>
  );
};

export default SystemCategoryIcon;
