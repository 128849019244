"use client";
import React, { FC } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import { josefin } from "app/fonts";
import { usePathname, useRouter } from "navigation";

import { IcnExpandMode } from "theme/icons";
import { colorText50 } from "theme/colors";

type FooterLanguageChooseProps = {
  availableLanguage: string[];
  language?: string;
};

const FooterLanguageChoose: FC<FooterLanguageChooseProps> = ({
  availableLanguage,
  language,
}) => {
  const router = useRouter();
  const pathname = usePathname();

  const changeHandler = (e) => {
    router.push(pathname, { locale: e.target.value });
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        borderTopWidth: "1px",
        borderRightWidth: "1px",
        borderBottomWidth: "1px",
        borderLeftWidth: "1px",
        borderRadius: "0px 0px 0px 0px",
        width: "49px",
        height: "16px",
        m: 0,
        p: 0,
        "& .MuiInputBase-root": {
          m: 0,
          p: 0,
          minHeight: "16px",
          justifyContent: "center",
          display: "inline-flex",
        },
        "& .MuiInputLabel-root": {
          m: 0,
          p: 0,
          minHeight: "16px",
          display: "inline-flex",
        },
        "& .MuiMenuItem-root": {
          m: 0,
          p: 0,
          height: "16px",
          display: "inline-flex",
        },
        "& .MuiSelect-select": {
          m: 0,
          p: 0,
          height: "16px",
          alignItems: "center",
          display: "inline-flex",
        },
        "& .MuiInput-input": { m: 0, p: 0 },
        "& .MuiInputBase-input": {
          color: "#fff",
          fontSize: 14,
          fontWeight: "Regular",
          fontFamily: josefin.style.fontFamily,
          textAlign: "left",
          p: "0 !important",
        },
      }}
    >
      <InputLabel color="primary" />
      <Select
        MenuProps={{ disableScrollLock: true }}
        color="primary"
        disableUnderline
        displayEmpty
        inputProps={{ "aria-label": "Website lang" }}
        value={!language ? "" : language}
        onChange={changeHandler}
        IconComponent={(props) => (
          <IcnExpandMode {...props} style={{ color: colorText50 }} />
        )}
      >
        {availableLanguage.includes("pl") && (
          <MenuItem value={"pl"}>PL</MenuItem>
        )}
        {availableLanguage.includes("cs") && (
          <MenuItem value={"cs"}>CS</MenuItem>
        )}
        {availableLanguage.includes("hu") && (
          <MenuItem value={"hu"}>HU</MenuItem>
        )}
        {availableLanguage.includes("de") && (
          <MenuItem value={"de"}>DE</MenuItem>
        )}
        {availableLanguage.includes("en") && (
          <MenuItem value={"en"}>EN</MenuItem>
        )}
      </Select>
      <FormHelperText />
    </FormControl>
  );
};

export default FooterLanguageChoose;
