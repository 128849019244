const colorText50 = "#fff";
const colorText100 = "#F4F7FA";
const colorText200 = "#EEF2F7";
const colorText300 = "#DCE1E8";
const colorText400 = "#CBD3DD";
const colorText500 = "#B7C2D3";
const colorText600 = "#A0AEC3";
const colorText700 = "#727B89";
const colorText800 = "#2D3034";
const colorText900 = "#1C1E20";
const colorPrimary100 = "#E8F4FF";
const colorPrimary200 = "#B9DBEF";
const colorPrimary500 = "#1BA8CE";
const colorPrimary700 = "#164194";
const colorOrange = "#FC9527";
const colorGreen = "#0FC261";
const colorAlergie = "#22D3BD";
const colorAlergieLightBg = "#E2F7F3";
const colorZKrwi = "#FF5454";
const colorZKrwiLightBg = "#FBEBEB";
const colorGenetyczne = "#8383EA";
const colorGenetyczneLightBg = "#F3F1FF";
const colorPrenatalne = "#EC87D8";
const colorPrenatalneLightBg = "#FAECF8";
const colorZWymazu = "#FC9527";
const colorZWymazuLightBg = "#FFF5EA";
const colorMikroflora = "#FDD22F";
const colorMikrofloraLightBg = "#FDF7E3";
const colorSuplementy = "#E2F7E2";
const colorSuplementyLightBg = "#E2F7E2";
const colorLogoVisa = "#142688";
const colorLogoMaster1 = "#EB001B";
const colorLogoMaster2 = "#F79E1B";
const colorLogoMaster3 = "#FF5F00";
const colorLogoZg1 = "#0077BE";
const colorLogoZg2 = "#1961AC";
const colorGray100 = "rgba(255, 255, 255, 0.1)";
const colorGray600 = "#A0AEC3";

export {
  colorAlergie,
  colorAlergieLightBg,
  colorGenetyczne,
  colorGenetyczneLightBg,
  colorGray100,
  colorGray600,
  colorGreen,
  colorLogoMaster1,
  colorLogoMaster2,
  colorLogoMaster3,
  colorLogoVisa,
  colorLogoZg1,
  colorLogoZg2,
  colorMikroflora,
  colorMikrofloraLightBg,
  colorOrange,
  colorPrenatalne,
  colorPrenatalneLightBg,
  colorPrimary100,
  colorPrimary200,
  colorPrimary500,
  colorPrimary700,
  colorSuplementy,
  colorSuplementyLightBg,
  colorText100,
  colorText200,
  colorText300,
  colorText400,
  colorText50,
  colorText500,
  colorText600,
  colorText700,
  colorText800,
  colorText900,
  colorZKrwi,
  colorZKrwiLightBg,
  colorZWymazu,
  colorZWymazuLightBg,
};
