export const API_ROUTES = {
  //Global configuration
  GLOBAL_CONFIG: "/config",

  //test result check
  TEST_RESULT_CHECK: "/test-result/check",

  //autocomplete
  ARTICLES_AUTOCOMPLETE: "/articles-autocomplete",
  SEARCHER_AUTOCOMPLETE: "/searcher-autocomplete",

  //rating
  RATING_INSERT: "/rating/put",

  // contacts
  CONTACT_SUCCESS: "/contact/success",
  CONTACT_FOR_EMPLOYEES: "/contact/for-employees/success",
  CONTACT_RETURNS: "/contact/returns/success",
  CONTACT_ESTABLISHING_KINSHIP: "/contact/establishing-kinship/success",
  CONTACT_COOPERATION_FOR_DIETICIANS:
    "/contact/cooperation-for-dieticians/success",
  CONTACT_COOPERATION_FOR_DOCTORS: "/contact/cooperation-for-doctors/success",

  //activation
  ACTIVATION_ACTIVATE: "/activation/activate",
  ACTIVATION_CHECK: "/activation/check",

  //card
  CARD_CHECKOUT: "/cart/checkout",
  CARD_PROMO: "/cart/promo",
  CARD_REMOVE: "/cart/remove",
  CARD_UPDATE_QUANTITY: "/cart/update-quantity",
  CARD_CONFIG: "/cart/config",
  CARD_SAVE: "/cart/save",
  CARD_ADD: "/cart/add",
  CARD_PAYMENT_COMPLETE: "/cart/complete",
  QUICK_CART_EDIT_SIMULATION: "/cart/recalculate-simulation",
  QUICK_CART_EDIT_ACCEPT: "/cart/recalculate-accept",
  QUICK_CART_COMPLETE: "/cart/quick-complete-save",

  //map
  MAP_POINTS_BY_ATTRIBUTES: "/map/points-by-attributes",
  MAP_POINTS_DEFAULT: "/map/default-points",
  MAP_NEARBY_POINTS: "/map/nearby-points",
  MAP_PLACE_NAME: "/map/place-name",

  //filtration
  FILTRATION_GET_COUNT: "/filtration/count",

  //newsletter
  NEWSLETTER_ADD: "/newsletter/add",
};
