import { FC, memo } from "react";

import {
  IcnAlergie,
  IcnBadaniaDNA,
  IcnBadaniaPrenatalne,
  IcnBadanieKrwi,
  IcnBadanieMoczu,
  IcnBadanieZWymazu,
  IcnDostawaKurierem,
  IcnKoronawirus,
  IcnMikrofloraJelitowa,
  IcnNietolerancjePokarmowe,
  IcnSuplementy,
} from "theme/icons";

type BannerIconsProps = {
  iconName: string;
  alt: string;
  color: string;
};

const BannerIcons: FC<BannerIconsProps> = memo(function BannerIcons({
  iconName,
  alt,
  color,
}) {
  switch (iconName) {
    case "IcnDostawaKurierem":
      return (
        <IcnDostawaKurierem
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnNietolerancjePokarmowe":
      return (
        <IcnNietolerancjePokarmowe
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnBadaniaDNA":
      return (
        <IcnBadaniaDNA
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnBadaniaPrenatalne":
      return (
        <IcnBadaniaPrenatalne
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnMikrofloraJelitowa":
      return (
        <IcnMikrofloraJelitowa
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnBadanieKrwi":
      return (
        <IcnBadanieKrwi
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnAlergie":
      return (
        <IcnAlergie role="img" aria-label={alt ? alt : "icon"} fill={color} />
      );
    case "IcnKoronawirus":
      return (
        <IcnKoronawirus
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnBadanieMoczu":
      return (
        <IcnBadanieMoczu
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnSuplementy":
      return (
        <IcnSuplementy
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
    case "IcnBadanieZWymazu":
      return (
        <IcnBadanieZWymazu
          role="img"
          aria-label={alt ? alt : "icon"}
          fill={color}
        />
      );
  }
});

export default BannerIcons;
