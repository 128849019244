import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const axiosImport =
  process.env.NODE_ENV === "development" ? axios : setupCache(axios);

const backend = axiosImport.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

backend.defaults.headers.post["Content-Type"] = "application/json";

export default backend;
